import putApiMethod from './common/putApiMethod';

// This api will add a new FC to a FC Type.
export const addFcToFcTypeApi = async (data, user, idToken) => {
  //Encoding because Fc Type contains '/'
  const url = `/api/stowSla/fcTypes/${encodeURIComponent(data.FcType)}/fcs/${data.Fc}?username=${user}`;

  const response = await putApiMethod(idToken, url, null);
  
  return response;
};
export default addFcToFcTypeApi;
