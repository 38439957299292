import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ViewFC from '../pages/view/viewFC';
import ViewFCtype from '../pages/view/viewFCtype';
import ViewAuditHistory from '../pages/view/viewAuditHistory';
import Home from '../pages/home';
import Error from '../pages/error';
import UpdateStowSlaValue from '../pages/update/updateStowSlaValue';
import AddFcToFcType from '../pages/addfc/addFcToFcType';

class Content extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/viewfctype"
          render={props => <ViewFCtype {...props} user={this.props.user} idToken={this.props.idToken} />}
        />
        <Route
          exact
          path="/viewfc"
          render={props => <ViewFC {...props} user={this.props.user} idToken={this.props.idToken} />}
        />
        <Route
          exact
          path="/updatestowsla"
          render={props => <UpdateStowSlaValue {...props} user={this.props.user} idToken={this.props.idToken} />}
        />
        <Route
          exact
          path="/addfctofctype"
          render={props => <AddFcToFcType {...props} user={this.props.user} idToken={this.props.idToken} />}
        />

        <Route
          exact
          path="/viewaudithistory"
          render={props => <ViewAuditHistory {...props} user={this.props.user} idToken={this.props.idToken} />}
        />

        <Route path="*" component={Error} />
      </Switch>
    );
  }
}

export default Content;
