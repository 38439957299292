import React from 'react';
import { Select, Flash } from '@amzn/awsui-components-react';
import getFCTypeOptions from '../../api/getFCTypeOptions';
import constant from '../layout/constant.json';

class AddDropDownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: 'loading',
      fcTypeOptions: [],
      message: null,
      selectedOption: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      cloneIdToken: null,
    });
  }

  componentDidUpdate = () => {
    if (this.props.idToken !== undefined && this.state.cloneIdToken !== this.props.idToken) {
      this.loadFcTypeOptions();
      this.setState({
        cloneIdToken: this.props.idToken,
      });
    }
    this.previousContext = this.context;
  };

  loadFcTypeOptions = async () => {
    try {
      const fcTypeOptions = await getFCTypeOptions(this.props.idToken);
      this.setState({ fcTypeOptions, dataStatus: 'finished', message: null });
    } catch (error) {
      this.setState({ message: error.message });
    }
  };

  handleChange = event => {
    this.setState({
      selectedOption: event.detail.selectedOption,
    });
    this.props.getFcType(this.state.selectedOption.label);
  };

  render() {
    const { fcTypeOptions } = this.state;
    return (
      <div>
        <Flash visible={this.state.message !== null} type={constant.Error} dismissible>
          {this.state.message}
        </Flash>
        <h4>{constant.Fc_Type_Label}</h4>
        <Select
          placeholder={constant.Fc_Type}
          options={fcTypeOptions}
          onChange={this.handleChange}
          statusType={this.state.dataStatus}
          loadingText="Loading Fc Types"
          selectedLabel={constant.Selected}
        />
      </div>
    );
  }
}

export default AddDropDownButton;
