import React from 'react';
import { Select, Checkbox, Input, Button, Textarea, BreadcrumbGroup } from '@amzn/awsui-components-react';
import constant from '../../layout/constant.json';

class UpdateStowSlaValueConfirmation extends React.Component {
  confirm = () => {
    this.props.getConfirmation(true);
  };

  cancel = () => {
    this.props.getConfirmation(false);
  };

  render() {
    const selectedfcType = { label: this.props.data.fcType };
    const selectedunitType = { label: this.props.data.unitType };
    return (
      <div>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_Update_StowSla_Value}></BreadcrumbGroup>

        <h4>{constant.Fc_Type_Label} : </h4>
        <Select selectedOption={selectedfcType} selectedLabel={constant.Selected} disabled />

        <h4>{constant.Unit_Type_Label} : </h4>
        <Select selectedOption={selectedunitType} selectedLabel={constant.Selected} disabled />

        <h4>{constant.Container_Type_Label} : </h4>
        {this.props.data.containers.map(d => {
          return (
            <Checkbox key={d.label} disabled checked={d.isChecked}>
              {d.label}
            </Checkbox>
          );
        })}
        <br />

        <h4> {constant.StowSla_Value_Label} : </h4>
        <Input value={this.props.data.stowSlaValue} disabled autocomplete={false} />

        <h4>{constant.Reason_Label} : </h4>
        <Textarea value={this.props.data.reason} disabled />
        <br />
        <br />
        <Button variant="primary" onClick={this.confirm}>
          {' '}
          Confirm{' '}
        </Button>
        <Button onClick={this.cancel}>Cancel</Button>
      </div>
    );
  }
}

export default UpdateStowSlaValueConfirmation;
