import React from 'react';
import { Select } from '@amzn/awsui-components-react';
import constant from '../layout/constant.json';

class AddUnitType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: 'finished',
      selectedOption: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({
      selectedOption: event.detail.selectedOption,
    });
    this.props.getUnitType(this.state.selectedOption.label);
  };

  render() {
    const { stowSlaData } = this.props;
    const unitTypes = stowSlaData.map(item => item.unitType);
    const distinctUnitTypes = [...new Set(unitTypes)];
    const unitTypeOptions = [];
    for (let i = 0; i < distinctUnitTypes.length; ++i) {
      unitTypeOptions.push({ label: distinctUnitTypes[i], id: distinctUnitTypes[i] });
    }
    return (
      <div>
        <h4>{constant.Unit_Type_Label}</h4>
        <Select
          placeholder={constant.Unit_Type_Value}
          options={unitTypeOptions}
          onChange={this.handleChange}
          statusType={this.state.dataStatus}
          selectedLabel={constant.Selected}
        />
      </div>
    );
  }
}

export default AddUnitType;
