import React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';

export const getAuditHistoryTableColumnDefinition = () => {
  const response = [
    {
      id: 'username',
      sortingField: 'id',
      header: 'Username',
      cell: item => item.username || '-',
    },
    {
      id: 'date',
      header: 'Date of Update',
      cell: item => item.date || '-',
    },
    // We have changed the operation column data
    // to use granularity and explain about what 
    // operation was performed.
    // It uses the granularity part of the api
    // response.
    {
      id: 'granularity',
      header: 'Operation',
      cell: item => (
        <div>
          {item.operation === 'FC/ADD' ? (
            <div>
              New Fc was Added to FcType
              <ul>
                <li>Fc : {item.granularity.fc}</li>
              </ul>
            </div>
          ) : item.operation === 'FC/MODIFY' ? (
            <div>
              Fc was moved from one FcType to Another FcType
              <ul>
                <li>Fc : {item.granularity.fc}</li>
              </ul>
            </div>
          ) : (
            <ColumnLayout>
              <div>
                StowSla Value was updated for following Data :
                <ul>
                  <li>FcType : {item.granularity.fcType}</li>
                  <li>UnitType : {item.granularity.unitType}</li>
                  <li>
                    ContainerTypes :
                    <ul>
                      {item.granularity.containerTypes.map(value => {
                        return <li>{value}</li>;
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </ColumnLayout>
          )}
        </div>
      ),
      maxwidth: 400,
      minWidth: '250px',
    },
    {
      id: 'oldValue',
      header: 'OldValue',
      cell: item => (
        <ul>
          {item.operation === 'FC/ADD' ? (
            '-'
          ) : item.operation === 'FC/MODIFY' ? (
            <ColumnLayout>
              {Object.entries(item.oldValue).map(([key, value]) => {
                return <div>{value}</div>;
              })}
            </ColumnLayout>
          ) : (
            <ColumnLayout>
              {Object.entries(item.oldValue).map(([key, value]) => {
                return (
                  <li>
                    {key} : {value / 3600}
                  </li>
                );
              })}
            </ColumnLayout>
          )}
        </ul>
      ),
      maxwidth: 400,
      minWidth: '200px',
    },
    {
      id: 'newValue',
      header: 'New Value',
      cell: item => item.newValue || '-',
    },
    {
      id: 'reason',
      header: 'Reason for Update Operation',
      cell: item => item.reason || '-',
    },
  ];
  return response;
};
export default getAuditHistoryTableColumnDefinition;
