// This Method is used to convert the stowSlaAuditHistoryData
// into downloadable format that can fit in a CSV file.
export const convertDataToDownloadable = async Data => {
  let modifiedData = [];
  for (let i = 0; i < Data.length; ++i) {
    const stowSlaData = Data[i];
    let granularity, oldValues;
    // Here we are converting the object into a string
    // so that it can fit in a single field.
    if (stowSlaData.field !== 'FC') {
      granularity =
        'StowSla Value was updated for following Data : {"UnitType" : ' +
        JSON.stringify(stowSlaData.granularity.unitType) +
        '}{"FcType": ' +
        JSON.stringify(stowSlaData.granularity.fcType) +
        '}{"ContainerTypes":[';
      for (let i = 0; i < stowSlaData.granularity.containerTypes.length; i++) {
        granularity = granularity + ' ' + JSON.stringify(stowSlaData.granularity.containerTypes[i]);
      }
      granularity = granularity + ']}';

      oldValues = '[';
      for (const [key, value] of Object.entries(stowSlaData.oldValue)) {
        oldValues = oldValues + '{' + key + ':' + value / 3600 + '}';
      }
      oldValues = oldValues + ']';
    } else {
      if (stowSlaData.operation === 'FC/MODIFY') {
        granularity =
          'Fc was moved from one FcType to Another FcType : { FC : ' + JSON.stringify(stowSlaData.granularity.fc) + '}';
        oldValues = Object.entries(stowSlaData.oldValue).map(([key, value]) => {
          return value;
        });
      } else {
        granularity = 'New Fc was Added to FcType : { FC : ' + JSON.stringify(stowSlaData.granularity.fc) + '}';
        oldValues = '-';
      }
    }
    modifiedData.push({
      username: stowSlaData.username,
      date: stowSlaData.date,
      operation: granularity,
      oldValue: oldValues,
      newValue: stowSlaData.newValue,
      reason: stowSlaData.reason,
    });
  }
  return modifiedData;
};
export default convertDataToDownloadable;
