import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import constant from '../layout/constant.json';

class AddInputStowSla extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StowSlaValue: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({
      StowSlaValue: event.detail.value,
    });
    this.props.getStowSlaValue(this.state.StowSlaValue);
  };

  render() {
    return (
      <div>
        <h4>{constant.StowSla_Value_Label}</h4>
        <Input placeholder={constant.StowSla_Value} type="number" onChange={this.handleChange} />
      </div>
    );
  }
}

export default AddInputStowSla;
