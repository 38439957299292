import React from 'react';
import constant from '../layout/constant.json';

const Home = () => {
  return (
    <div>
      <h1>{constant.Home}</h1>
    </div>
  );
};

export default Home;
