import React from 'react';
import CheckBox from './checkbox';
import constant from '../layout/constant.json';

class AddContainerType extends React.Component {
  render() {
    const { containerOptions } = this.props;

    this.handleAllChecked = event => {
      const containers = containerOptions;
      containers.forEach(container => (container.isChecked = event.target.checked));
      this.props.getContainer(containers);
    };
    this.handleChange = event => {
      const containers = containerOptions;
      containers.forEach(container => {
        if (container.label === event.target.value) container.isChecked = event.target.checked;
      });
      this.props.getContainer(containers);
    };

    return (
      <div>
        <h4>{constant.Container_Type_Label}</h4>
        <ul>
          <li>
            <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" /> All
          </li>
          {containerOptions.map(container => {
            return <CheckBox handleChange={this.handleChange} {...container} />;
          })}
        </ul>
      </div>
    );
  }
}

export default AddContainerType;
