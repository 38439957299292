import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import constant from '../layout/constant.json';

class AddInputFC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fc: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({
      Fc: event.detail.value,
    });
    this.props.getFc(this.state.Fc);
  };

  render() {
    return (
      <div>
        <h4>{constant.Fc_Label}</h4>
        <Input placeholder={constant.Fc_Placeholder} onChange={this.handleChange} />
      </div>
    );
  }
}

export default AddInputFC;
