export const getAuditFilterOptions = items => {
  const fcType = items.map(item => item.granularity.fcType);
  const username = items.map(item => item.username);
  const date = items.map(item => item.date);
  const newValue = items.map(item => item.newValue);
  const response = [
    {
      groupValuesLabel: 'fcType',
      propertyKey: 'fcType',
      propertyLabel: 'FcType',
      values: fcType,
    },
    {
      groupValuesLabel: 'username',
      propertyKey: 'username',
      propertyLabel: 'Username',
      values: username,
    },
    {
      groupValuesLabel: 'date',
      propertyKey: 'date',
      propertyLabel: 'Date',
      values: date,
    },
    {
      groupValuesLabel: 'newValue',
      propertyKey: 'newValue',
      propertyLabel: 'New Value',
      values: newValue,
    },
  ];
  return response;
};
export default getAuditFilterOptions;
