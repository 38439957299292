import React from 'react';
import { Flash } from '@amzn/awsui-components-react';
import AddFcToFcTypeForm from './addFcToFcTypeForm';
import AddFcToFcTypeConfirmForm from './addFcToFcTypeConfirmForm';
import addFcToFcTypeApi from '../../../api/addFcToFcTypeApi';
import constant from '../../layout/constant.json';

class AddFcToFcType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Fc: null,
        FcType: null,
      },
      formPage: true,
      message: null,
      flashType: constant.Error,
    };
  }

  getFormData = formData => {
    this.setState({
      data: formData,
      formPage: false,
    });
  };

  removeFlash = () => {
    this.setState({
      message: null,
    });
  };

  getConfirmation = status => {
    this.setState({
      confirmed: status,
    });
    if (this.state.confirmed === true) {
      this.sendFcData();
    } else {
      this.setState({
        message: constant.On_Cancelled,
        flashType: constant.Error,
        formPage: true,
      });
    }
  };

  sendFcData = async () => {
    try {
      await addFcToFcTypeApi(this.state.data, this.props.user, this.props.idToken);
      this.setState({
        message: constant.On_Success,
        flashType: constant.Success,
        data: {},
        formPage: true,
      });
    } catch (error) {
      this.setState({
        message: error.message,
        flashType: constant.Error,
        formPage: true,
      });
    }
  };

  render() {
    const { formPage } = this.state;
    return (
      <div>
        <Flash visible={this.state.message !== null} type={this.state.flashType} dismissible>
          {this.state.message}
        </Flash>
        {formPage ? (
          <AddFcToFcTypeForm
            getFormData={this.getFormData}
            removeFlash={this.removeFlash}
            idToken={this.props.idToken}
          />
        ) : (
          <AddFcToFcTypeConfirmForm data={this.state.data} getConfirmation={this.getConfirmation} />
        )}
      </div>
    );
  }
}

export default AddFcToFcType;
