export const getColumnDefinitions = () => {
  const response = [
    {
      id: 'unitType',
      header: 'Unit Type',
      cell: item => item.unitType,
      maxWidth: '140px',
      width: 300,
    },
    {
      id: 'container',
      header: 'Container',
      cell: item => item.container,
      maxwidth: 300,
      minWidth: '140px',
    },
    {
      id: 'stowsla',
      header: 'StowSla Value(in hours)',
      cell: item => item.stowsla,
      maxwidth: 300,
      minWidth: '140px',
    },
    {
      id: 'lastUpdatedtime',
      header: 'Last Updated Time',
      cell: item => item.lastUpdatedtime,
      maxwidth: 300,
      minWidth: '140px',
    },
  ];
  return response;
};
export default getColumnDefinitions;
