export default {
  beta: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_znxiHy6lv",
    APP_CLIENT_ID: "2l6lm0fj9ok2rlds8cplppll9b",
    IDENTITY_POOL_ID: "us-east-1:07549a88-22bd-45ab-b6ea-fd869cfe480f",
    OAUTH: {
      domain: "stow-sla-portal-na-beta.auth.us-east-1.amazoncognito.com",
      scope: [
        "openid"
      ],
      redirectSignIn: "https://beta.na.stow-sla-portal.aft.amazon.dev/",
      redirectSignOut: "https://beta.na.stow-sla-portal.aft.amazon.dev/",
      responseType: "code",
    }
  },
  na: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_2XjtpeWIn",
    APP_CLIENT_ID: "25nnks7db3bqs8rdfkp6amsq2l",
    IDENTITY_POOL_ID: "us-east-1:a95863d2-dca6-4843-8b00-955a79d22cd6",
    OAUTH: {
      domain: "stow-sla-portal-na.auth.us-east-1.amazoncognito.com",
      scope: [
        "openid"
      ],
      redirectSignIn: "https://na.stow-sla-portal.aft.amazon.dev/",
      redirectSignOut: "https://na.stow-sla-portal.aft.amazon.dev/",
      responseType: "code",
    }
  },
  eu: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_byGKZyUEx",
    APP_CLIENT_ID: "12hso4rsvn0qdpe8ktemug3884",
    IDENTITY_POOL_ID: "eu-west-1:02b78441-fb36-4825-bb7e-0af2fd1e258d",
    OAUTH: {
      domain: "stow-sla-portal-eu.auth.eu-west-1.amazoncognito.com",
      scope: [
        "openid"
      ],
      redirectSignIn: "https://eu.stow-sla-portal.aft.amazon.dev/",
      redirectSignOut: "https://eu.stow-sla-portal.aft.amazon.dev/",
      responseType: "code",
    }
  }
};