import React from 'react';
import { Flash } from '@amzn/awsui-components-react';
import UpdateStowSlaValueForm from './updateStowSlaValueForm';
import UpdateStowSlaValueFormConfirmation from './updateStowSlaValueConfirmation';
import updateStowSlaValueApi from '../../../api/updateStowSlaValueApi';
import constant from '../../layout/constant.json';

class UpdateStowSlaValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      formPage: true,
      message: null,
      flashType: constant.Error,
      confirmed: false,
    };
  }

  getConfirmation = status => {
    this.setState({
      confirmed: status,
    });
    if (this.state.confirmed === true) {
      this.updateData();
    } else {
      this.setState({
        message: constant.On_Cancelled,
        flashType: constant.Error,
        formPage: true,
      });
    }
  };

  updateData = async () => {
    try {
      const onlyContainers = [];
      const { data } = this.state;
      for (let i = 0; i < data.containers.length; ++i) {
        if (data.containers[i].isChecked === true) {
          onlyContainers.push(data.containers[i].label);
        }
      }
      const dataToBeSend = {
        fcType: data.fcType,
        stowSlaValue: data.stowSlaValue * 3600,
        containers: onlyContainers,
        unitType: data.unitType,
        reason: data.reason,
      };
      await updateStowSlaValueApi(dataToBeSend, this.props.user, this.props.idToken);
      this.setState({
        message: constant.On_Success,
        flashType: constant.Success,
        data: {},
        formPage: true,
      });
    } catch (error) {
      this.setState({
        message: error.message,
        flashType: constant.Error,
        formPage: true,
        data: {},
      });
    }
  };

  removeFlash = () => {
    this.setState({
      message: null,
    });
  };

  getFormData = formData => {
    this.setState({
      data: formData,
      formPage: false,
    });
  };

  render() {
    const { formPage } = this.state;
    return (
      <div>
        <Flash visible={this.state.message !== null} type={this.state.flashType} dismissible>
          {this.state.message}
        </Flash>
        {formPage ? (
          <UpdateStowSlaValueForm
            removeFlash={this.removeFlash}
            getFormData={this.getFormData}
            idToken={this.props.idToken}
          />
        ) : (
          <UpdateStowSlaValueFormConfirmation getConfirmation={this.getConfirmation} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default UpdateStowSlaValue;
