import React from 'react';
import constant from './constant.json';

class Header extends React.Component {
  render() {
    return (
      <header className="header" style={{ background: '#232F3E', color: 'white' }}>
        <div className="awsui-grid awsui-util-p-s">
          <div className="awsui-row">
            <div className="col-10 awsui-text-large">
              <h1>{constant.Header}</h1>
            </div>
            <div className="col-2">
              <h2 align="right">{this.props.user}</h2>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
