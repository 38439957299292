import getApiMethod from './common/getApiMethod';

//This api will return all the Fc Types.
export const getFCTypeOptions = async idToken => {
  const url = '/api/fcTypes';
  let modifiedResponse = [];

  const response = await getApiMethod(idToken, url);

  for (let i = 0; i < response.fcTypes.length; ++i) {
    modifiedResponse.push({ label: response.fcTypes[i], id: response.fcTypes[i] });
  }

  return modifiedResponse;
};
export default getFCTypeOptions;
