import getApiMethod from './common/getApiMethod';

// This api returns StowSla Data for a particular FCType.
export const getStowSlaDataByFcType = async (fcType, idToken) => {
  //Encoding because Fc Type contains '/'
  const url = `/api/stowSla/fcTypes/${encodeURIComponent(fcType)}`;

  const response = await getApiMethod(idToken, url);

  let modifiedResponse = [];

  for (let i = 0; i < response.stowSlaContexts.length; ++i) {
    const stowSlaData = response.stowSlaContexts[i];
    const date = new Date(stowSlaData.lastUpdatedTimeInUtc);
    const dateTime = date.toUTCString();
    modifiedResponse.push({
      unitType: stowSlaData.unitType,
      container: stowSlaData.containerType,
      stowsla: stowSlaData.stowSlaValue / 3600,
      lastUpdatedtime: dateTime,
    });
  }

  return modifiedResponse;
};
export default getStowSlaDataByFcType;
