import React from 'react';
import constant from '../layout/constant.json';

const Error = () => {
  return (
    <div>
      <h1 align="Center">{constant.Error_Not_Found}</h1>
    </div>
  );
};

export default Error;
