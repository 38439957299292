import React from 'react';
import { Button, Flash, BreadcrumbGroup } from '@amzn/awsui-components-react';
import AddDropDownButton from '../../common/addDropDownButton';
import CreateTable from '../../common/table/createTable';
import getStowSlaDataByFcType from '../../../api/getStowSlaDataByFcType';
import getFilteringOptions from '../../common/table/getFilteringOptions';
import getFcFilteredList from '../../common/table/getFcFilteredList';
import constant from '../../layout/constant.json';
import FcListTable from '../../common/table/fcListTable';
import getFcList from '../../../api/getFcList';

class ViewFCtype extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fcListTable: true,
      fcType: constant.FcType,
      message: null,
      stowSlaData: [],
      fcList: [],
      filteringOptions: [],
      fcFilteredList: [],
      loading: false,
    };
    this.getStowSlaData = this.getStowSlaData.bind(this);
    this.getFcListData = this.getFcListData.bind(this);
  }

  getStowSlaData = () => {
    this.setState({
      fcListTable: false,
    });
    if (this.state.fcType === constant.FcType) {
      this.setState({
        message: constant.Fc_Type,
      });
    } else {
      this.setState({
        message: null,
        loading: true,
      });
      this.loadStowSlaData();
    }
  };

  getFcListData = () => {
    this.setState({
      fcListTable: true,
    });
    if (this.state.fcType === constant.FcType) {
      this.setState({
        message: constant.Fc_Type,
      });
    } else {
      this.setState({
        message: null,
        loading: true,
      });
      this.loadFcList();
    }
  };

  getFcType = selectedFcType => {
    this.setState({
      fcType: selectedFcType,
      message: null,
      stowSlaData: [],
      fcList: [],
    });
  };

  loadStowSlaData = async () => {
    try {
      const stowSlaData = await getStowSlaDataByFcType(this.state.fcType, this.props.idToken);
      this.setState({ stowSlaData });
      const filteringOptions = getFilteringOptions(this.state.stowSlaData);
      this.setState({
        filteringOptions,
      });
    } catch (error) {
      this.setState({
        message: error.message,
        stowSlaData: [],
        filteringOptions: [],
      });
    }
    this.setState({
      loading: false,
    });
  };

  loadFcList = async () => {
    try {
      const fcList = await getFcList(this.state.fcType, this.props.idToken);
      this.setState({ fcList });
      const fcFilteredList = getFcFilteredList(this.state.fcList);
      this.setState({
        fcFilteredList,
      });
    } catch (error) {
      this.setState({
        message: error.message,
        fcList: [],
      });
    }
    this.setState({
      loading: false,
    });
  };

  render() {
    let { fcListTable } = this.state;
    return (
      <div>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_View_By_Fc_Type_Items}></BreadcrumbGroup>

        <Flash visible={this.state.message !== null} type={constant.Error} dismissible>
          {this.state.message}
        </Flash>
        <br />

        <AddDropDownButton getFcType={this.getFcType} idToken={this.props.idToken} />
        <br />
        <Button variant="primary" onClick={this.getStowSlaData}>
          View StowSla
        </Button>
        <Button variant="normal" onClick={this.getFcListData}>
          View List of Fcs belonging to {this.state.fcType}
        </Button>
        <br />
        <br />
        {fcListTable ? (
          <FcListTable
            fcList={this.state.fcList}
            fcType={this.state.fcType}
            fcFilteredList={this.state.fcFilteredList}
            loading={this.state.loading}
          />
        ) : (
          <CreateTable
            stowSlaData={this.state.stowSlaData}
            filteringOptions={this.state.filteringOptions}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}

export default ViewFCtype;
