import React from 'react';

export const CheckBox = props => {
  return (
    <div>
      <li>
        <input
          key={props.label}
          onClick={props.handleChange}
          type="checkbox"
          checked={props.isChecked}
          value={props.label}
        />{' '}
        {props.label}
      </li>
    </div>
  );
};

export default CheckBox;
