export const getFilteringOptions = items => {
  const container = items.map(item => item.container);
  const unitType = items.map(item => item.unitType);

  const response = [
    {
      groupValuesLabel: 'Unit type values',
      propertyKey: 'unitType',
      propertyLabel: 'Unit Type',
      values: unitType,
    },
    {
      groupValuesLabel: 'Container values',
      propertyKey: 'container',
      propertyLabel: 'Container',
      values: container,
    },
  ];
  return response;
};
export default getFilteringOptions;
