import '@amzn/awsui-components-react/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import Amplify from "@aws-amplify/core";
import apiConfig from './apiConfig.js';
import cognitoConfig from "./cognitoConfig";

var hostname = window.location.hostname;
var domainName = `${hostname}`.split(".")[0];

const getBaseURL = () => {
  return apiConfig[domainName].API;
}

const getRegion = () => {
  return apiConfig[domainName].REGION;
}

const getCognitoRegion = () => {
  return cognitoConfig[domainName].REGION;
}

const getUserPoolId = () => {
  return cognitoConfig[domainName].USER_POOL_ID;
}

const getIdentityPoolId = () => {
  return cognitoConfig[domainName].IDENTITY_POOL_ID;
}

const getWebClientId = () => {
  return cognitoConfig[domainName].APP_CLIENT_ID;
}

const getOauth = () => {
  return cognitoConfig[domainName].OAUTH;
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: getCognitoRegion(),
    userPoolId: getUserPoolId(),
    identityPoolId: getIdentityPoolId(),
    userPoolWebClientId: getWebClientId(),
    oauth : getOauth()
  },
  API: {
    endpoints: [
      {
        name: "AFTStowSlaServiceLambda",
        endpoint: getBaseURL(),
        region: getRegion()
      },
    ]
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

