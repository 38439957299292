export const getFcListColumn = () => {
  const response = [
    {
      id: 'fc',
      header: 'Fc',
      cell: item => item.fc,
    },
  ];
  return response;
};
export default getFcListColumn;
