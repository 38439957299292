import React from 'react';
import { BreadcrumbGroup, Flash, Tabs, Button } from '@amzn/awsui-components-react';

import constant from '../../layout/constant.json';
import getAuditFilterOptions from '../../common/table/getAuditFilterOptions';
import getAuditHistoryData from '../../../api/getAuditHistoryData';
import getAuditHistoryTableColumnDefinition from '../../common/table/getAuditHistoryTableColumnDefinition';
import UseTable from '../../common/table/useTable';
import AddDateRange from '../../common/addDateRange';
import AddTiles from '../../common/addTiles';
import { CSVLink } from 'react-csv';
import convertDataToDownloadable from '../../common/convertDataToDownloadable';

class ViewAuditHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: true,
      toDate: '',
      fromDate: '',
      lowerTimeLimitInMilliseconds: Date.now() - 86400000 * 30,
      upperTimeLimitInMilliseconds: Date.now(),
      value: '',
      message: null,
      auditData: [],
      auditFilterOptions: [],
      downloadableData: [],
      columnDefinition: [],
      loading: false,
      tab: constant.First,
      //This is done to consider the full current Day
      currentTime: Math.floor(Date.parse(Date().toLocaleString()) / 86400000) * 86400000 + 86399999,
    };
    this.loadAuditData = this.loadAuditData.bind(this);
    this.verifyDateBeforeRequest = this.verifyDateBeforeRequest.bind(this);
  }

  verifyDateBeforeRequest = () => {
    if (this.state.toDate === '' || this.state.fromDate === '') {
      this.setState({
        message: constant.Enter_Date_Message,
        auditData: [],
        disableButton: true,
      });
    } else if (this.state.lowerTimeLimitInMilliseconds > this.state.upperTimeLimitInMilliseconds) {
      this.setState({
        message: constant.Lower_Limit_Upper_Limit_Validation_Message,
        auditData: [],
        disableButton: true,
      });
    } else if (this.state.currentTime < this.state.upperTimeLimitInMilliseconds) {
      this.setState({
        message: constant.Date_Selection_Limit,
        auditData: [],
        disableButton: true,
      });
    } else {
      this.loadAuditData();
    }
  };

  loadAuditData = async () => {
    try {
      this.setState({
        loading: true,
        auditData: [],
      });

      const auditData = await getAuditHistoryData(
        this.props.idToken,
        this.state.lowerTimeLimitInMilliseconds,
        this.state.upperTimeLimitInMilliseconds,
      );
      this.setState({
        auditData: auditData,
        disableButton: false,
      });

      const downloadableData = await convertDataToDownloadable(this.state.auditData);
      this.setState({
        downloadableData: downloadableData,
        message: null,
      });

      const auditFilterOptions = getAuditFilterOptions(this.state.auditData);
      this.setState({
        auditFilterOptions,
        message: null,
      });
    } catch (error) {
      this.setState({
        message: error.message,
        auditData: [],
        disableButton: true,
      });
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount() {
    this.loadColumnDefinition();
    this.setState({
      cloneIdToken: null,
    });
  }

  // We have added this to update the
  // idToken on refreshing the page and
  // get us data using api.
  componentDidUpdate = () => {
    if (this.props.idToken !== undefined && this.state.cloneIdToken !== this.props.idToken) {
      this.getRealtiveDate(this.state.lowerTimeLimitInMilliseconds, this.state.upperTimeLimitInMilliseconds);
      this.setState({
        cloneIdToken: this.props.idToken,
      });
    }
    this.previousContext = this.context;
  };

  loadColumnDefinition = () => {
    const columnDefinition = getAuditHistoryTableColumnDefinition();
    this.setState({ columnDefinition });
  };

  getDateRange = (fromDate, toDate) => {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      lowerTimeLimitInMilliseconds: Date.parse(fromDate),
      //Adding 86399999 for considering the whole day of upperlimit
      upperTimeLimitInMilliseconds: Date.parse(toDate) + 86399999,
      message: null,
      tab: constant.Second,
    });
    this.verifyDateBeforeRequest();
  };

  getRealtiveDate = (lowerTimeLimit, upperTimeLimit) => {
    this.setState({
      lowerTimeLimitInMilliseconds: lowerTimeLimit,
      upperTimeLimitInMilliseconds: upperTimeLimit,
      message: null,
      tab: constant.First,
    });
    this.loadAuditData();
  };

  downloadData = async () => {
    const downloadableData = await convertDataToDownloadable(this.state.auditData);
    console.log(downloadableData);
    this.setState({
      downloadableData: downloadableData,
      message: null,
    });
  };

  render() {
    const tabs = [
      {
        label: constant.Relative_Time_Range,
        id: constant.First,
        content: <AddTiles getRealtiveDate={this.getRealtiveDate} idToken={this.props.idToken} />,
      },
      {
        label: constant.Absolute_Time_Range,
        id: constant.Second,
        content: <AddDateRange getDateRange={this.getDateRange} />,
      },
    ];

    return (
      <div>
        <Flash visible={this.state.message !== null} type={constant.Error} dismissible>
          {this.state.message}
        </Flash>
        <br />
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_Audit_History}></BreadcrumbGroup>

        <Tabs tabs={tabs} activeTabId={this.state.tab}></Tabs>

        <Button disabled={this.state.disableButton}>
          <CSVLink data={this.state.downloadableData} filename={constant.Audit_History_Data_Filename} target="_blank">
            {' '}
            Export StowSla Audit History Data{' '}
          </CSVLink>
        </Button>
        <br />
        <br />

        <UseTable
          columnDefinition={this.state.columnDefinition}
          filterOptions={this.state.auditFilterOptions}
          dataItems={this.state.auditData}
          tableHeader={constant.BreadcrumbGroup_Audit_History_Table_Header}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default ViewAuditHistory;
