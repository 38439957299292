import getApiMethod from './common/getApiMethod';

// This api returns StowSlaAuditHistoryData.
// request : {
//   "lowerTimeRangeInUtc" : @lowerTimeLimit,
// "upperTimeRangeInUtc" : @upperTimeLimit
// }
// response : List of stowSlaAuditHistoryDataContexts
export const getAuditHistoryData = async (idToken, lowerTimeLimit, upperTimeLimit) => {
  const url = `/api/stowSlaAuditHistory/startTimeInUTC/${lowerTimeLimit}/endTimeInUTC/${upperTimeLimit}`;

  const response = await getApiMethod(idToken, url);

  let modifiedResponse = [];
  for (let i = 0; i < response.stowSlaAuditHistoryDataContexts.length; ++i) {
    const stowSlaAuditData = response.stowSlaAuditHistoryDataContexts[i];
    //This converts the date in epoch milliseconds to UTC format
    const lastUpdatedTime = new Date(stowSlaAuditData.lastUpdatedTimeInUtc).toUTCString();
    let newValue = stowSlaAuditData.newValue;
    let containerTypes = '';
    //This is to convert the StowSla Value in hrs
    if (stowSlaAuditData.OperationDetails.field === 'STOWSLA') {
      newValue /= 3600;
      for (let j = 0; j < stowSlaAuditData.Granularity.containerTypes.length; j++) {
        // This is done to add string based search for containerTypes.
        containerTypes = containerTypes + ' ' + stowSlaAuditData.Granularity.containerTypes[j];
      }
    }

    modifiedResponse.push({
      field: stowSlaAuditData.OperationDetails.field,
      granularity: stowSlaAuditData.Granularity,
      oldValue: stowSlaAuditData.oldValue,
      newValue: newValue,
      containerTypes: containerTypes,
      reason: stowSlaAuditData.reasonForUpdating,
      operation: stowSlaAuditData.OperationDetails.field + '/' + stowSlaAuditData.OperationDetails.operation,
      date: lastUpdatedTime,
      username: stowSlaAuditData.username,
      fcType: stowSlaAuditData.Granularity.fcType,
    });
  }

  return modifiedResponse;
};
export default getAuditHistoryData;
