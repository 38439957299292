import React from 'react';
import { Input, Select, Button, BreadcrumbGroup } from '@amzn/awsui-components-react';
import constant from '../../layout/constant.json';

class AddFcToFcTypeConfirmForm extends React.Component {
  confirm = () => {
    this.props.getConfirmation(true);
  };

  cancel = () => {
    this.props.getConfirmation(false);
  };

  render() {
    const { data } = this.props;
    const selected = { label: data.FcType, id: data.FcType };
    return (
      <div>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_Add_Fc_To_Fc_Type_Items}></BreadcrumbGroup>

        <h4> {constant.Fc_Label} : </h4>
        <Input placeholder={constant.Fc_Placeholder} value={data.Fc} disabled autocomplete={false} />
        <br />
        <br />

        <h4>{constant.Fc_Type_Label} : </h4>
        <Select placeholder={constant.Fc_Type} selectedOption={selected} selectedLabel={constant.Selected} disabled />
        <br />
        <br />
        <Button variant="primary" onClick={this.confirm}>
          Confirm
        </Button>
        <Button onClick={this.cancel}>Cancel</Button>
      </div>
    );
  }
}

export default AddFcToFcTypeConfirmForm;
