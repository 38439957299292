import API from '@aws-amplify/api';

//This method is used to make the update call apis 
export const putApiMethod = async (idToken, url, dataToBeSend) => {
  try {
    const response = await API.put('AFTStowSlaServiceLambda', url, {
      credentials: 'same-origin',
      body: dataToBeSend,
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export default putApiMethod;
