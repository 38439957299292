import { putApiMethod } from './common/putApiMethod';

// This api will update the StowSla Value.
export const updateStowSlaValueApi = async (data, user, idToken) => {
  //Encoding because Fc Type contains '/'
  const url = `/api/stowSla/fcTypes/${encodeURIComponent(data.fcType)}?username=${user}`;
  const dataToBeSend = {
    stowSlaValue: data.stowSlaValue,
    unitType: data.unitType,
    containerTypes: data.containers,
    reasonForUpdating: data.reason,
  };

  const response = await putApiMethod(idToken, url, dataToBeSend);
  return response;
};
export default updateStowSlaValueApi;
