import React from 'react';
import { Button, Flash, BreadcrumbGroup } from '@amzn/awsui-components-react';
import AddDropDownButton from '../../common/addDropDownButton';
import AddInputFC from '../../common/addInputFC';
import constant from '../../layout/constant.json';

class AddFcToFcTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fc: '',
      FcType: '',
      data: null,
      messageFc: null,
      messageFcType: null,
      fcRegex: /^[A-Z]{3}[1-9]$/,
    };
    this.getFcType = this.getFcType.bind(this);
    this.getFc = this.getFc.bind(this);
    this.addFc = this.addFc.bind(this);
  }

  getFc = inputFc => {
    this.setState({
      Fc: inputFc,
      messageFc: null,
    });
    this.props.removeFlash();
  };

  getFcType = selectedFcType => {
    this.setState({
      FcType: selectedFcType,
      messageFcType: null,
    });
    this.props.removeFlash();
  };

  addFc = () => {
    this.props.removeFlash();
    let errorcount = 0;
    const fcRegex = /^[A-Z]{3}[1-9]$/;
    const { Fc, FcType } = this.state;

    if (Fc === '') {
      errorcount++;
      this.setState({
        messageFc: constant.Enter_Fc,
      });
    } else if (!Fc.match(fcRegex)) {
      errorcount++;
      this.setState({
        messageFc: constant.Enter_Valid_Fc,
      });
    } else {
      this.setState({
        messageFc: null,
      });
    }
    if (FcType === '') {
      this.setState({
        messageFcType: constant.Fc_Type,
      });
      errorcount++;
    } else {
      this.setState({
        messageFcType: null,
      });
    }
    if (errorcount === 0) {
      this.setState({
        messageFcType: null,
        messageFc: null,
        data: {
          Fc: this.state.Fc,
          FcType: this.state.FcType,
        },
      });
      this.props.getFormData(this.state.data);
    }
  };

  render() {
    return (
      <div>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_Add_Fc_To_Fc_Type_Items}></BreadcrumbGroup>

        <Flash visible={this.state.messageFc !== null} type={constant.Error} dismissible>
          {this.state.messageFc}
        </Flash>
        <br />
        <Flash visible={this.state.messageFcType !== null} type={constant.Error} dismissible>
          {this.state.messageFcType}
        </Flash>
        <AddInputFC getFc={this.getFc} />
        <AddDropDownButton getFcType={this.getFcType} idToken={this.props.idToken} />
        <br />
        <Button variant="primary" onClick={this.addFc}>
          Add Fc
        </Button>
      </div>
    );
  }
}

export default AddFcToFcTypeForm;
