import React from 'react';
import { Tiles } from '@amzn/awsui-components-react';

class AddTiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lowerTimeLimit: Date.now() - 86400000 * 30,
      upperTimeLimit: Date.now(),
      value: '30',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({
      value: event.detail.value,
      lowerTimeLimit: this.state.upperTimeLimit - 86400000 * event.detail.value,
    });
    this.props.getRealtiveDate(this.state.lowerTimeLimit, this.state.upperTimeLimit);
  };

  render() {
    const items = [
      {
        value: '30',
        description: 'View StowSla Audit Data for last 1 Month',
        label: '1 Month',
      },
      {
        value: '60',
        description: 'View StowSla Audit Data for last 2 Months',
        label: '2 Months',
      },
      {
        value: '90',
        description: 'View StowSla Audit Data for last 3 Months',
        label: '3 Months',
      },
      {
        value: '180',
        description: 'View StowSla Audit Data for last 6 Months',
        label: '6 Months',
      },
    ];

    const columns = 4;
    return (
      <div>
        <Tiles value={this.state.value} items={items} onChange={this.handleChange} columns={columns}></Tiles>
      </div>
    );
  }
}

export default AddTiles;
