import React from 'react';
import { Button, Flash, Textarea, BreadcrumbGroup } from '@amzn/awsui-components-react';
import AddDropDownButton from '../../common/addDropDownButton';
import AddInputStowSla from '../../common/addInputStowSla';
import AddContainerType from '../../common/addContainerType';
import AddUnitType from '../../common/addUnitType';
import getStowSlaDataByFcType from '../../../api/getStowSlaDataByFcType';
import constant from '../../layout/constant.json';

class UpdateStowSlaValueForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFcType: null,
      messageStowSlaValue: null,
      messageContainer: null,
      messageUnitType: null,
      messageReason: null,
      reason: '',
      fcType: '',
      stowSlaValue: '',
      containers: [],
      unitType: '',
      data: {},
      stowSlaData: [],
      containerOptions: [],
    };
    this.getReason = this.getReason.bind(this);
  }

  getReason = event => {
    this.setState({
      reason: event.detail.value,
      messageReason: null,
    });
    this.props.removeFlash();
  };

  getFcType = selectedFcType => {
    this.setState({
      fcType: selectedFcType,
      messageFcType: null,
    });
    this.props.removeFlash();
    this.loadStowSlaData();
  };

  loadStowSlaData = async () => {
    try {
      const stowSlaData = await getStowSlaDataByFcType(this.state.fcType, this.props.idToken);
      this.setState({
        stowSlaData,
        message: null,
      });
    } catch (error) {
      this.setState({
        message: error.message,
      });
    }
    this.setState({
      containerOptions: [],
    });
  };

  getUnitType = selectedUnitType => {
    this.setState({
      unitType: selectedUnitType,
      messageUnitType: null,
    });
    this.props.removeFlash();
    this.loadContainerData();
  };

  loadContainerData = async () => {
    this.setState({ containerOptions: [] });
    const { containerOptions } = this.state;
    const { stowSlaData, unitType } = this.state;
    for (let i = 0; i < stowSlaData.length; ++i) {
      if (stowSlaData[i].unitType === unitType) {
        containerOptions.push({ label: stowSlaData[i].container, key: stowSlaData[i].container, isChecked: false });
      }
    }
    this.setState({ containerOptions });
  };

  getStowSlaValue = inputStowSlaValue => {
    this.setState({
      stowSlaValue: inputStowSlaValue,
      messageStowSlaValue: null,
    });
    this.props.removeFlash();
  };

  getContainer = selectedContainerType => {
    this.setState({
      containers: selectedContainerType,
      messageContainer: null,
    });
    this.props.removeFlash();
  };

  updateStowSla = () => {
    this.props.removeFlash();
    let errorcount = 0;
    const { fcType, stowSlaValue, containers, unitType, reason } = this.state;

    if (reason === '') {
      errorcount++;
      this.setState({
        messageReason: constant.Message_Reason,
      });
    } else {
      this.setState({
        messageReason: null,
      });
    }

    if (containers.length === 0) {
      errorcount++;
      this.setState({
        messageContainer: constant.Select_Containers,
      });
    } else {
      this.setState({
        messageContainer: null,
      });
    }

    if (unitType === '') {
      errorcount++;
      this.setState({
        messageUnitType: constant.Unit_Type_Value,
      });
    } else {
      this.setState({
        messageUnitType: null,
      });
    }

    if (fcType === '') {
      this.setState({
        messageFcType: constant.Fc_Type,
      });
      errorcount++;
    } else {
      this.setState({
        messageFcType: null,
      });
    }

    if (stowSlaValue === '') {
      this.setState({
        messageStowSlaValue: constant.StowSla_Value,
      });
      errorcount++;
    } else {
      this.setState({
        messageStowSlaValue: null,
      });
    }

    if (errorcount === 0) {
      this.setState({
        messageFcType: null,
        messageStowSlaValue: null,
        message: null,
        messageContainer: null,
        messageReason: null,
        messageUnitType: null,
        data: {
          fcType,
          stowSlaValue,
          containers,
          unitType,
          reason,
        },
      });
      this.props.getFormData(this.state.data);
    }
  };

  render() {
    return (
      <div>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_Update_StowSla_Value}></BreadcrumbGroup>

        <Flash visible={this.state.message !== null} type={constant.Error} dismissible>
          {this.state.message}
        </Flash>

        <Flash visible={this.state.messageFcType !== null} type={constant.Error} dismissible>
          {this.state.messageFcType}
        </Flash>

        <Flash visible={this.state.messageUnitType !== null} type={constant.Error} dismissible>
          {this.state.messageUnitType}
        </Flash>

        <Flash visible={this.state.messageContainer !== null} type={constant.Error} dismissible>
          {this.state.messageContainer}
        </Flash>

        <Flash visible={this.state.messageStowSlaValue !== null} type={constant.Error} dismissible>
          {this.state.messageStowSlaValue}
        </Flash>

        <Flash visible={this.state.messageReason !== null} type={constant.Error} dismissible>
          {this.state.messageReason}
        </Flash>

        <AddDropDownButton getFcType={this.getFcType} idToken={this.props.idToken} />
        <br />

        <AddUnitType getUnitType={this.getUnitType} stowSlaData={this.state.stowSlaData} />
        <br />

        <AddContainerType getContainer={this.getContainer} containerOptions={this.state.containerOptions} />
        <br />

        <AddInputStowSla getStowSlaValue={this.getStowSlaValue} />
        <br />

        <h4>{constant.Reason_Label}</h4>
        <Textarea placeholder={constant.Message_Reason} onChange={this.getReason} />
        <br />
        <br />

        <Button variant="primary" onClick={this.updateStowSla}>
          Update StowSla Value
        </Button>
      </div>
    );
  }
}

export default UpdateStowSlaValueForm;
