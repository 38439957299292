import React from 'react';
import {
  Table,
  TablePropertyFiltering,
  filteringCountTextFunction,
  TablePagination,
} from '@amzn/awsui-components-react';
import constant from '../../layout/constant.json';

class UseTable extends React.Component {
  render() {
    const { dataItems, filterOptions, loading, tableHeader, columnDefinition } = this.props;
    return (
      <div>
        <Table
          columnDefinitions={columnDefinition}
          loadingText={constant.Loading_Text}
          loading={loading}
          items={dataItems}
          wrapLines={false}
          header={<h2>{tableHeader}</h2>}
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>{constant.No_Resources}</b>
              </div>
              <p className="awsui-util-mb-s">{constant.No_Data_To_Display}</p>
            </div>
          }
        >
          <TablePropertyFiltering
            filteringOptions={filterOptions}
            groupValuesText={constant.Group_Values_Text}
            groupPropertiesText={constant.Group_Properties_Text}
            operationAndText={constant.Operation_And_Text}
            operationNotAndText={constant.Operation_Not_And_Text}
            operationOrText={constant.Operation_Or_Text}
            operationNotOrText={constant.Operation_Not_Or_Text}
            clearFiltersText={constant.Clear_Filters_Text}
            placeholder={constant.Table_Property_Filtering_Placeholder}
            allowFreeTextFiltering
            filteringCountTextFunction={filteringCountTextFunction}
          />

          <TablePagination pageSize={10} />
        </Table>
      </div>
    );
  }
}

export default UseTable;
