import React from 'react';
import { Flash, Button, BreadcrumbGroup, Badge } from '@amzn/awsui-components-react';
import AddInputFC from '../../common/addInputFC';
import CreateTable from '../../common/table/createTable';
import getStowSlaDataByFc from '../../../api/getStowSlaDataByFc.jsx';
import getFilteringOptions from '../../common/table/getFilteringOptions';
import constant from '../../layout/constant.json';

class ViewFC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fc: constant.Fc,
      fcType: constant.FcType,
      message: null,
      stowSlaData: [],
      filteringOptions: [],
      loading: false,
    };
    this.submit = this.submit.bind(this);
  }

  submit = () => {
    const fcRegex = /^[A-Z]{3}[1-9]$/;
    const { fc } = this.state;
    if (fc === constant.Fc) {
      this.setState({
        loading: false,
        message: constant.Enter_Fc,
      });
    } else if (!fc.match(fcRegex)) {
      this.setState({
        loading: false,
        message: constant.Enter_Valid_Fc,
      });
    } else {
      this.setState({
        message: null,
        loading: true,
      });
      this.loadStowSlaData();
    }
  };

  getFc = inputFc => {
    this.setState({
      fc: inputFc,
      message: null,
      fcType : constant.FcType
    });
  };

  loadStowSlaData = async () => {
    try {
      const stowSlaData = await getStowSlaDataByFc(this.state.fc, this.props.idToken);
      this.setState({ stowSlaData });
      this.setState({
        fcType: stowSlaData[0].fcType,
      });
      const filteringOptions = getFilteringOptions(this.state.stowSlaData);
      this.setState({ filteringOptions });
    } catch (error) {
      this.setState({
        message: error.message,
        stowSlaData: [],
        filteringOptions: [],
      });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div>
        <Flash visible={this.state.message !== null} type={constant.Error} dismissible>
          {this.state.message}
        </Flash>
        <BreadcrumbGroup label="Breadcrumbs" items={constant.BreadcrumbGroup_View_By_Fc_Items}></BreadcrumbGroup>
        <AddInputFC getFc={this.getFc} />
        <br />
        <Button variant="primary" onClick={this.submit}>
          View StowSla
        </Button>{' '}
        <Badge color={constant.Grey}>
          {this.state.fc} Belongs to {this.state.fcType}
        </Badge>
        <br />
        <br />
        <CreateTable
          stowSlaData={this.state.stowSlaData}
          filteringOptions={this.state.filteringOptions}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default ViewFC;
