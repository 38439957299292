import { SideNavigation } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const sideNavBarHeader = {
  href: '#/',
  text: 'Self Service Portal',
};

const sideNavBarItems = [
  {
    type: 'section',
    text: 'View StowSla',
    items: [
      { type: 'link', text: 'FC Type', href: '#/viewfctype' },
      { type: 'link', text: 'FC', href: '#/viewfc' },
    ],
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'Update StowSla Value',
    href: '#/updatestowsla',
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'Add Fc to Fc Type',
    href: '#/addfctofctype',
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'View Audit History',
    href: '#/viewaudithistory',
  },
  { type: 'divider' },
];

const Navigation = ({ location }) => {
  const { pathname } = location;
  return <SideNavigation activeHref={`#${pathname}`} header={sideNavBarHeader} items={sideNavBarItems} />;
};

Navigation.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Navigation);
