import API from '@aws-amplify/api';

//This api is used to make call for get apis.
export const getApiMethod = async (idToken, url) => {
  try {
    const response = await API.get('AFTStowSlaServiceLambda', url, {
      credentials: 'same-origin',
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export default getApiMethod;
