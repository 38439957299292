import getApiMethod from './common/getApiMethod';

// This api returns List of Fcs for a particular FCType.
export const getFcList = async (fcType, idToken) => {
  //Encoding because Fc Type contains '/'
  const url = `/api/fcs/${encodeURIComponent(fcType)}`;

  const response = await getApiMethod(idToken, url);

  let modifiedResponse = [];

  for (let i = 0; i < response.fcList.length; ++i) {
    modifiedResponse.push({ fc: response.fcList[i] });
  }

  return modifiedResponse;
};
export default getFcList;
