import getApiMethod from './common/getApiMethod';

//This api will return the StowSla Data for a particular FC
export const getStowSlaDataByFc = async (fc, idToken) => {
  const url = `/api/stowSla/fcs/${fc}`;

  const response = await getApiMethod(idToken, url);

  let modifiedResponse = [];

  for (let i = 0; i < response.stowSlaContexts.length; ++i) {
    const stowSlaData = response.stowSlaContexts[i];
    const date = new Date(stowSlaData.lastUpdatedTimeInUtc);
    const dateTime = date.toUTCString();
    modifiedResponse.push({
      unitType: stowSlaData.unitType,
      container: stowSlaData.containerType,
      stowsla: stowSlaData.stowSlaValue / 3600,
      lastUpdatedtime: dateTime,
      fcType: response.fcType,
    });
  }

  return modifiedResponse;
};
export default getStowSlaDataByFc;
