import React from 'react';
import { DatePicker, Button } from '@amzn/awsui-components-react';

class AddDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toDate: '',
      fromDate: '',
      lowerTimeLimit: '',
      upperTimeLimit: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = () => {
    this.props.getDateRange(this.state.fromDate, this.state.toDate);
  };

  render() {
    return (
      <div>
        <div className="awsui-grid awsui-util-p-s">
          <div className="awsui-row">
            <div className="col-0">
              <DatePicker
                onChange={({ detail }) =>
                  this.setState({
                    fromDate: detail.value,
                  })
                }
                value={this.state.fromDate}
                ariaRequired
                autoFocus
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
              />
            </div>
            <div className="col-0">
              <h4>to</h4>
            </div>

            <div className="col-0">
              <DatePicker
                onChange={({ detail }) =>
                  this.setState({
                    toDate: detail.value,
                  })
                }
                value={this.state.toDate}
                ariaRequired
                autoFocus
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
              />
            </div>
            <div className="col-0">
              <Button variant="primary" onClick={this.handleChange}>
                View Audit History
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDateRange;
