export default {
    beta: {
        API: "https://glr3a0m9d2.execute-api.us-east-1.amazonaws.com/beta",
        REGION: "us-east-1"
    },
    eu: {
        API: "https://7kj932rzi0.execute-api.eu-west-1.amazonaws.com/prod",
        REGION: 'eu-west-1'
    },
    na: {
        API: " https://nqd7m5sh11.execute-api.us-east-1.amazonaws.com/prod",
        REGION: 'us-east-1'
    }
}