import React from 'react';
import {
  Table,
  TablePropertyFiltering,
  filteringCountTextFunction,
  TablePagination,
} from '@amzn/awsui-components-react';
import getFcListColumn from './getFcListColumn.jsx';
import constant from '../../layout/constant.json';

class FcListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fcListColumn: [],
    };
  }

  componentDidMount = () => {
    this.loadFcListColumn();
  };

  loadFcListColumn = () => {
    const fcListColumn = getFcListColumn();
    this.setState({ fcListColumn });
  };

  render() {
    const { fcList, fcFilteredList, loading, fcType } = this.props;
    const { fcListColumn } = this.state;
    return (
      <div>
        <Table
          columnDefinitions={fcListColumn}
          loadingText={constant.Loading_Text}
          loading={loading}
          items={fcList}
          wrapLines={false}
          header={<h2>List of Fcs for {fcType}</h2>}
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>{constant.No_Resources}</b>
              </div>
              <p className="awsui-util-mb-s">{constant.No_Data_To_Display}</p>
            </div>
          }
        >
          <TablePropertyFiltering
            filteringOptions={fcFilteredList}
            groupValuesText={constant.Group_Values_Text}
            groupPropertiesText={constant.Group_Properties_Text}
            operationAndText={constant.Operation_And_Text}
            operationNotAndText={constant.Operation_Not_And_Text}
            operationOrText={constant.Operation_Or_Text}
            operationNotOrText={constant.Operation_Not_Or_Text}
            clearFiltersText={constant.Clear_Filters_Text}
            placeholder={constant.Table_Property_Filtering_Placeholder}
            allowFreeTextFiltering
            filteringCountTextFunction={filteringCountTextFunction}
          />

          <TablePagination pageSize={20} />
        </Table>
      </div>
    );
  }
}

export default FcListTable;
