import React from 'react';
import {
  Table,
  TablePropertyFiltering,
  filteringCountTextFunction,
  TablePagination,
} from '@amzn/awsui-components-react';
import getColumnDefinitions from './getColumnDefinitions.jsx';
import constant from '../../layout/constant.json';

class CreateTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefinitions: [],
    };
  }

  componentDidMount = () => {
    this.loadColumnDefinitions();
  };

  loadColumnDefinitions = () => {
    const columnDefinitions = getColumnDefinitions();
    this.setState({ columnDefinitions });
  };

  render() {
    const { columnDefinitions } = this.state;
    const { stowSlaData, filteringOptions, loading} = this.props;

    return (
      <div>
        <Table
          columnDefinitions={columnDefinitions}
          loadingText={constant.Loading_Text}
          loading={loading}
          items={stowSlaData}
          wrapLines={false}
          header={<h2>{constant.StowSla_Values}</h2>}
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>{constant.No_Resources}</b>
              </div>
              <p className="awsui-util-mb-s">{constant.No_Data_To_Display}</p>
            </div>
          }
        >
          <TablePropertyFiltering
            filteringOptions={filteringOptions}
            groupValuesText={constant.Group_Values_Text}
            groupPropertiesText={constant.Group_Properties_Text}
            operationAndText={constant.Operation_And_Text}
            operationNotAndText={constant.Operation_Not_And_Text}
            operationOrText={constant.Operation_Or_Text}
            operationNotOrText={constant.Operation_Not_Or_Text}
            clearFiltersText={constant.Clear_Filters_Text}
            placeholder={constant.Table_Property_Filtering_Placeholder}
            allowFreeTextFiltering
            filteringCountTextFunction={filteringCountTextFunction}
          />

          <TablePagination pageSize={20} />
        </Table>
      </div>
    );
  }
}

export default CreateTable;
