import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { AppLayout } from '@amzn/awsui-components-react';
import Header from './components/layout/header';
import Navigation from './components/layout/navigation';
import Content from './components/layout/content';
import Auth from '@aws-amplify/auth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      idToken: null,
    };
  }

  componentDidMount = () => {

    Auth.currentSession()
      .then(session => {
        const payload = session.getIdToken().decodePayload();
        this.setState({
          user: payload.identities[0].userId,
        });
      })
      .catch(err => {
        if (err === 'No current user') {
          Auth.federatedSignIn({ provider: 'AFTStowSlaPortal' });
        }
      });

    Auth.currentAuthenticatedUser().then(tokens => {
      this.setState({
        idToken: tokens.signInUserSession.idToken.jwtToken,
      });
    })
  };

  render() {
    return (
      <div className="awsui">
        <Router>
          <Header user={this.state.user} />
          <AppLayout
            content={<Content user={this.state.user} idToken={this.state.idToken} />}
            navigation={<Navigation />}
            navigationOpen={false}
            toolsHide={true}
          />
        </Router>
      </div>
    );
  }
}

export default App;
