export const getFcFilteredList = items => {
    const fc = items.map(item => item.fc);
  
    const response = [
      {
        groupValuesLabel: 'fc',
        propertyKey: 'fc',
        propertyLabel: 'Fc',
        values: fc
      }
    ];
    return response;
  };
  export default getFcFilteredList;
  